<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container @search="search" @reset="reset" :fields="searchFields">
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
              >
                {{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('update', 'stock')">
            <b-button variant="primary" :to="{ name: 'update-stock' }">{{
              $t("button.update")
            }}</b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :type="permissionType"
          :updatable="false"
        >
          <template #cell(itemCode)="data">
            <span v-if="data.item.item">
              {{ data.item.item.itemCode }}
            </span>
          </template>
          <template #cell(itemName)="data">
            <span v-if="data.item.item">
              {{ data.item.item.name }}
            </span>
          </template>
          <template #cell(stockLocation)="data">
            <span v-if="data.item.stockLocation">
              {{ data.item.stockLocation.name }}
            </span>
          </template>
          <template #cell(binCode)="data">
            <span v-if="data.item.item">
              {{ data.item.stockBin.code }}
            </span>
          </template>
          <template #cell(type)="data">
            <span :class="`text-${data.item.type}`">
              {{ $t("general." + data.item.type) }}
            </span>
          </template>
          <template #cell(quantity)="data">
            <span :class="`text-${data.item.type}`">
              {{ data.item.quantity }}
            </span>
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import { calculateDatesByPeriod } from "@/libs/helper.js";

const StockRepository = Repository.get("stock");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.endDate = dates[1];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        search: this.$route.query.search || null,
        type: this.$route.query.stockLocationId || "",
        stockLocationId: Number(this.$route.query.stockLocationId) || null,
        stockBinId: Number(this.$route.query.stockBinId) || null,
        itemId: Number(this.$route.query.itemId) || null,
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null,
        period: this.$route.query.period || null,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search(searchText) {
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.search = searchText;
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.params.search = "";
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      StockRepository.index({
        ...this.params,
        startDate: this.params.startDate
          ? `${this.params.startDate} 00:00:00`
          : null,
        endDate: this.params.endDate ? `${this.params.endDate} 23:59:59` : null,
        searchFields: this.searchFields,
        period: null,
      })
        .then((response) => {
          const data = response.data.data;
          this.items = [...data.list];
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const permissionType = "stock";
    return {
      fields,
      searchFields,
      permissionType,
    };
  },
};
</script>
export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'itemCode',
    label: 'field.itemCode',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'itemName',
    label: 'field.itemName',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'stockLocation',
    sortable: true,
    sortField: 'stockLocationId',
    label: 'field.location',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'binCode',
    label: 'field.binCode',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'quantity',
    label: 'field.quantity',
  },
  {
    key: 'type',
    label: 'field.type',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'remark',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
